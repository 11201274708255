import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const ChevronUpIcon: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  fill = "#000000",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      className={className}
    >
      <path
        id="Icon"
        d="M14.5697 10L16 8.47472L8 0L0 8.48329L1.4303 10L8 3.03342L14.5697 10Z"
      />
    </svg>
  );
};

export default ChevronUpIcon;
