import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  resetConfig,
  setConfigId,
  setConfigLoader,
} from "../../store/reducers/configReducer";
import { useNavigate, useParams } from "react-router-dom";
import PrismicTranslation from "../PrismicTranslation";
import { selectPreviousProductConfigId } from "../../store/reducers/uiReducer";
import { useFetchProductConfigMutation } from "../../api/nest";
import { ConfigLoader } from "../../types";
import BackIcon from "../ui/Icons/BackIcon";

interface ContinueShoppingProps {}

export const ContinueShopping: FC<ContinueShoppingProps> = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const previousProductConfigId = useAppSelector(selectPreviousProductConfigId);
  const [fetchProductConfig] = useFetchProductConfigMutation();

  const onClick = async () => {
    const response = (await fetchProductConfig({
      id: previousProductConfigId,
    })) as {
      data: ConfigLoader;
    };
    if (response && response.data) {
      dispatch(resetConfig());
      dispatch(setConfigId(previousProductConfigId));
      dispatch(setConfigLoader(response.data));
      navigate(`/${projectId}`);
    }
  };

  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-full flex items-center gap-2 ml-2 underline text-text text-sm"
    >
      <BackIcon />
      <span>
        <PrismicTranslation field={"continue_shopping"} simple />
      </span>
    </div>
  );
};
