import { ColorOption, ProductOption, Supplier } from "../../../types";
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ConfiguratorSelectorSubProductOption from "../ConfiguratorSelectorSubProductOption";
import { ConfiguratorLogoSelector } from "../ProjectLogos/ConfiguratorLogoSelector";
import { logoNames } from "../ProjectLogos/config";
import {
  getPrismicTranslation,
  getProductFeatureFabricPrice,
  isProductOptionWithLogo,
} from "../../../utils";
import PrismicTranslation from "../../PrismicTranslation";
import {
  selectLanguage,
  selectTranslations,
} from "../../../store/reducers/uiReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setSelectedProductOptions } from "../../../store/reducers/configReducer";
import { selectProjectLogos } from "../../../store/reducers/configReducer";
import ChevronDownIcon from "../../ui/Icons/ChevronDownIcon";

interface ConfiguratorSelectorProductOptionProps {
  defaultOption?: ProductOption[];
  option: ProductOption;
  selectOption: (option: ProductOption) => void;
  onColorChange: (option: ProductOption) => void;
  selectedProductOptions?: ProductOption[]; // all selected ProductOptions for this Product
  fabricSupplier: Supplier | null;
}

const ProductFeature: FC<ConfiguratorSelectorProductOptionProps> = ({
  option,
  selectOption,
  defaultOption,
  onColorChange,
  selectedProductOptions,
  fabricSupplier,
}) => {
  const [currentOption, setCurrentOption] = useState<ProductOption>(option);
  const [price, setPrice] = useState<number>();
  const [isExpanded, setIsExpanded] = useState(false);
  const language = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const productLogos = useAppSelector(selectProjectLogos);
  const dispatch = useAppDispatch();
  const hasSubOptions = useMemo(
    () => currentOption?.data?.children?.length > 0,
    [currentOption],
  );
  const productOptionWithLogo = useMemo(() => {
    return isProductOptionWithLogo(currentOption);
  }, [currentOption]);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    if (currentOption && productOptionWithLogo) {
      setIsEnabled(productLogos.length > 0);
    }
  }, [currentOption, productLogos, productOptionWithLogo]);

  const isSelected = useCallback(
    () =>
      defaultOption?.some(
        (productOption) => productOption?.id === currentOption?.id,
      ),
    [defaultOption, currentOption],
  );

  const selectedSubOption = defaultOption?.find(
    (productOption) =>
      currentOption?.data?.children?.find(
        (child) => child.option?.id === productOption?.id,
      ),
  );

  const toggleSubSelection = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleSelectOption = () => {
    if (!isEnabled && !isSelected()) {
      return;
    }
    // Set default for ProductOption with logo suboption
    if (productOptionWithLogo && !currentOption.data.logo_option) {
      onChangeOption("logo_option", logoNames[0]);
      return;
    }

    // If hasSubOptions && is expanded then close suboptions
    if (hasSubOptions && isExpanded) {
      setIsExpanded(false);
      return;
    }
    // If hasSubOptions && no suboption selected then open suboptions
    if (hasSubOptions && !selectedSubOption) {
      setIsExpanded(true);
      return;
    }
    // If hasSubOptions && suboption selected then deselect suboption
    if (hasSubOptions && selectedSubOption && !isExpanded) {
      dispatch(
        setSelectedProductOptions(
          defaultOption?.filter(
            (productOption) =>
              productOption.id !== option.id &&
              productOption.id !== selectedSubOption.id,
          ) || [],
        ),
      );
      return;
    }

    hasSubOptions ? setIsExpanded(true) : selectOption(currentOption);
  };

  const changeColorOption = (colorOption: ColorOption) => {
    const newOption = {
      ...option,
      data: { ...option.data, color_option: colorOption },
    };
    setCurrentOption(newOption);
    onColorChange(newOption);
  };

  const onChangeOption = (key: keyof ProductOption["data"], value: string) => {
    const newOption = {
      ...option,
      data: { ...option.data, [key]: value },
    };
    setCurrentOption(newOption);
    onColorChange(newOption);
  };

  // Set price based on currentOption (price in ProductOption.data.price) or
  // selectedProductOptions (price set in ProductOption.data.price from
  // ProductOption.data.variants)
  useEffect(() => {
    // Price based on fabric supplier, if any
    const productFeatureFabricPrice = getProductFeatureFabricPrice(
      currentOption,
      fabricSupplier,
    );
    if (typeof productFeatureFabricPrice === "number") {
      return setPrice(productFeatureFabricPrice);
    }
    // Price set in production.data.price
    if (currentOption && currentOption.data.price) {
      return setPrice(currentOption.data.price);
    } else if (!isSelected()) {
      setPrice(undefined);
      // ProductOptions is selected and has suboption selected. Use suboption price
    } else if (
      selectedProductOptions &&
      selectedProductOptions.length > 0 &&
      option &&
      selectedSubOption
    ) {
      const price = selectedSubOption.data.price;
      return setPrice(
        typeof price === "number" && price > 0 ? price : undefined,
      );
    } else if (
      selectedProductOptions &&
      selectedProductOptions.length > 0 &&
      option
    ) {
      const productOption = selectedProductOptions.find(
        (curr) => curr.id === option.id,
      );
      return setPrice(
        productOption && productOption.data.price > 0
          ? productOption.data.price
          : undefined,
      );
    }
    setPrice(undefined);
  }, [
    selectedProductOptions,
    option,
    currentOption,
    isSelected,
    fabricSupplier,
    selectedSubOption,
  ]);

  const optionName = useMemo(() => {
    return getPrismicTranslation(language, "name", currentOption.data);
  }, [language, currentOption]);

  const optionLabel = useMemo(() => {
    if (selectedSubOption) {
      return selectedSubOption.data.name;
    }
    if (translations && "product_options" in translations) {
      return `${currentOption?.data?.children?.length} ${translations["product_options"]}`;
    }
    return "";
  }, [translations, currentOption, selectedSubOption]);

  return (
    <div className="w-full flex flex-col flex-wrap items-center gap-1">
      <div
        key={currentOption.id}
        onClick={handleSelectOption}
        className={`${
          isSelected()
            ? "bg-primary-200 border-primary-300 "
            : isEnabled
            ? "hover:bg-neutrals-200 hover:border-neutrals-300"
            : ""
        } ${
          isEnabled ? "cursor-pointer " : ""
        } border border-gray w-full font-bold p-2.5 rounded flex-1`}
      >
        <div className="flex flex-col">
          <div title={optionName} className="flex flex-wrap gap-2">
            <div className="flex flex-col flex-1 gap-2 justify-center">
              <div className="flex justify-between">
                {optionName}
                {price && price > 0 && <div>{price}€</div>}
              </div>
              {hasSubOptions && (
                <div
                  onClick={toggleSubSelection}
                  className="relative flex flex-wrap justify-between text-text px-4 border border-gray bg-white rounded items-center"
                >
                  <span>{optionLabel}</span>
                  <ChevronDownIcon />
                  {isExpanded && (
                    <div className="absolute top-0 z-10 right-0 flex w-full flex-col mt-6 p-1 bg-white gap-2">
                      {currentOption?.data?.children?.map((child) => (
                        <ConfiguratorSelectorSubProductOption
                          close={() => setIsExpanded(false)}
                          parentOption={currentOption}
                          defaultOption={defaultOption}
                          key={child.option?.id}
                          id={child.option?.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {currentOption?.data.fabrics && (
                <div className="w-full flex gap-8">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      id={currentOption?.data.name + "_main"}
                      name={currentOption?.data.name + "_type"}
                      type="radio"
                      value="main"
                      onClick={() => changeColorOption("main")}
                      defaultChecked={
                        currentOption?.data.color_option === "main" ||
                        !currentOption?.data.color_option
                      }
                      className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                    />
                    <label
                      htmlFor={currentOption?.data.name + "_main"}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      <PrismicTranslation field="main_color" simple />
                    </label>
                  </div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      id={currentOption?.data.name + "_contrast"}
                      name={currentOption?.data.name + "_type"}
                      value="contrast"
                      type="radio"
                      onClick={() => changeColorOption("contrast")}
                      defaultChecked={
                        currentOption?.data.color_option === "contrast"
                      }
                      className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                    />
                    <label
                      htmlFor={currentOption?.data.name + "_contrast"}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      <PrismicTranslation field="contrast_color" simple />
                    </label>
                  </div>
                </div>
              )}
              {productOptionWithLogo && (
                <ConfiguratorLogoSelector
                  productOption={currentOption}
                  onChange={onChangeOption}
                  isSelected={isSelected()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeature;
