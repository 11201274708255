import { FC, useEffect, useState } from "react";
import Title from "../../ui/Title";
import { CartProduct, Product, ProductGroup } from "../../../types";
import CartProductCard from "./CartProductCard";
import {
  useFetchProductGroupsQuery,
  useFetchProductsQuery,
} from "../../../api/nest";

interface CompleteYourLookProps {
  setIsOpen?: (isOpen: boolean) => void;
  cartItem: CartProduct;
}

const CompleteYourLook: FC<CompleteYourLookProps> = ({
  setIsOpen,
  cartItem,
}) => {
  const { data: productGroups } = useFetchProductGroupsQuery({});
  const { data: products } = useFetchProductsQuery({});

  const [showCompleteYourLook, setShowCompleteYourLook] = useState(true);
  const [completeYourLookProductGroups, setCompleteYourLookProductGroups] =
    useState<ProductGroup[]>([]);

  // Only show CompleteYourLook if there are CompleteYourLook items available
  useEffect(() => {
    if (products && productGroups && cartItem) {
      const relevantProductGroups = productGroups.filter(
        (productGroup: ProductGroup) => {
          // Exclude the product group associated with the cart item
          if (productGroup.id === cartItem.productGroup.id) {
            return false;
          }

          // Filter products in this product group
          const productGroupProducts = products.filter(
            (product: Product) => product.data.parent.id === productGroup.id,
          );

          // Check if there's at least one valid product in the group
          return productGroupProducts.some((product: Product) => {
            const hasSameFabric = product.data?.main_fabrics?.some(
              (fabric) => fabric.fabric?.id === cartItem?.fabric?.main?.id,
            );
            const isDifferentProduct = product.id !== cartItem.product.id;
            return hasSameFabric && isDifferentProduct;
          });
        },
      );

      // Limit to 2 relevant product groups
      const limitedProductGroups = relevantProductGroups.slice(0, 2);
      setCompleteYourLookProductGroups(limitedProductGroups);
      setShowCompleteYourLook(relevantProductGroups.length > 0);
    }
  }, [products, productGroups, cartItem]);

  if (showCompleteYourLook) {
    return (
      <>
        <Title label="add_to_cart_modal_complete_look" />
        <div className="flex gap-3">
          {completeYourLookProductGroups.map((productGroup: ProductGroup) => (
            <CartProductCard
              key={productGroup.id}
              productGroup={productGroup}
              cartItem={cartItem}
              setIsOpen={setIsOpen}
            />
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default CompleteYourLook;
