import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const PlusIcon: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  fill = "#000000",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={fill}
      className={className}
    >
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5625 5.10429V0H5.0625V5.10429H0V6.60429H5.0625V11.6667H6.5625V6.60429H11.6667V5.10429H6.5625Z"
      />
    </svg>
  );
};

export default PlusIcon;
