import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const CartIcon: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  fill = "#000000",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      className={className}
    >
      <path
        id="Icon"
        d="M4.79984 12.8C3.91987 12.8 3.20789 13.52 3.20789 14.4C3.20789 15.28 3.91987 16 4.79984 16C5.6798 16 6.39978 15.28 6.39978 14.4C6.39978 13.52 5.6798 12.8 4.79984 12.8ZM0 0V1.6H1.59995L4.47985 7.672L3.39988 9.632C3.27189 9.856 3.19989 10.12 3.19989 10.4C3.19989 11.28 3.91987 12 4.79984 12H14.3995V10.4H5.13582C5.02383 10.4 4.93583 10.312 4.93583 10.2L4.95983 10.104L5.67981 8.8H11.6396C12.2396 8.8 12.7676 8.472 13.0396 7.976L15.9035 2.784C15.9695 2.66191 16.0027 2.52479 15.9998 2.38602C15.997 2.24726 15.9582 2.11161 15.8873 1.99231C15.8164 1.87302 15.7157 1.77416 15.5951 1.7054C15.4746 1.63663 15.3383 1.60032 15.1995 1.6H3.36788L2.61591 0H0ZM12.7996 12.8C11.9196 12.8 11.2076 13.52 11.2076 14.4C11.2076 15.28 11.9196 16 12.7996 16C13.6795 16 14.3995 15.28 14.3995 14.4C14.3995 13.52 13.6795 12.8 12.7996 12.8Z"
      />
    </svg>
  );
};

export default CartIcon;
