import React, { useEffect } from "react";
import Layout from "./components/Layout";
import Login from "./components/Login";
import SplashScreen from "./components/SplashScreen";
import { useAppDispatch, useAppSelector } from "./hooks";
import {
  selectProductGroup,
  selectProjectId,
  setConfigId,
  setConfigLoader,
  setProjectId,
} from "./store/reducers/configReducer";
import { selectUser, setToken, setLoading } from "./store/reducers/userReducer";
import { UserManager } from "oidc-client";
import CreateProject from "./components/CreateProject";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "./hooks/useQuery";
import { ConfigLoader } from "./types";
import { addToCart } from "./store/reducers/cartReducer";
import { useGetDocumentByTypeQuery } from "./api/prismic";
import {
  convertLoadedProductToCartItem,
  isValidProjectIdFormat,
} from "./utils";
import {
  useFetchProductGroupsQuery,
  useFetchProductsQuery,
  useLazyFetchProjectQuery,
} from "./api/nest";
import {
  selectCountryLanguage,
  setProjectSetupComplete,
} from "./store/reducers/uiReducer";
import { useGetTranslationsQuery } from "./api/prismic";
import CircularProgress from "./components/ui/CircularProgress";
import { cloneDeep } from "lodash";

function AppSetup({ userManager }: { userManager: UserManager }) {
  const projectIdFromStore = useAppSelector(selectProjectId);
  const product = useAppSelector(selectProductGroup);
  const user = useAppSelector(selectUser);
  const [cartItems, setCartItems] = React.useState<ConfigLoader[]>([]);
  const [cartInitComplete, setCartInitComplete] = React.useState(false); // true if cart items in loaded project config are dispathed to  the redux store
  const dispatch = useAppDispatch();
  // url params for project and/or config id
  const { projectId: projectIdFromRoute, config } = useParams();
  const query = useQuery();
  // code from auth0
  const code = query.get("code") || "";
  const isLoggedIn = !!user;
  const projectId = projectIdFromStore || projectIdFromRoute;

  const userCountryLanguage = useAppSelector(selectCountryLanguage);
  const navigate = useNavigate();

  // Fetch translations at the start of the app
  const { isLoading: translationsLoading, isError: translationsError } =
    useGetTranslationsQuery({
      lang: userCountryLanguage,
    });

  // fetch data in background to reduce configurator loading time (cached)
  const { data: productGroups } = useFetchProductGroupsQuery({});
  const { data: products } = useFetchProductsQuery({});

  const { data: fabrics } = useGetDocumentByTypeQuery({
    type: "fabric",
    lang: "en-us",
  });
  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  // fetch existing project query
  const [fetchProjectQuery, { data: projectData, isSuccess, isError }] =
    useLazyFetchProjectQuery();

  useEffect(() => {
    if (isError) {
      navigate("/error", { replace: true }); // Redirect to an error page if project id does not exist
    }
  }, [isError]);

  // preload project and productconfigs if share url is used
  useEffect(() => {
    if (projectId && isValidProjectIdFormat(projectId)) {
      fetchProjectQuery(projectId);
    }
    if (isSuccess && projectData) {
      // set current project ID
      dispatch(setProjectId(projectData.id));
      // prefill cart items
      if (projectData.productConfigs.length > 0) {
        setCartItems(
          projectData.productConfigs?.filter(
            (config: ConfigLoader) => config.inCart,
          ),
        );
      } else {
        setCartInitComplete(true);
      }
      // if config id is provided, set it as selected config
      if (config) {
        const selectedConfig = projectData.productConfigs?.find(
          (productConfig: ConfigLoader) =>
            productConfig.id?.toString() === config,
        );
        if (selectedConfig) {
          dispatch(setConfigId(selectedConfig.id));
          dispatch(setConfigLoader(selectedConfig));
        }
        // if not use first config from project
      } else if (projectData.productConfigs?.length) {
        // Find last product config that is not in the cart and make it t.he current product config
        // Selecting a product config in cart would update the cart item.
        const productConfigsNotInCart = projectData.productConfigs.filter(
          (item: ConfigLoader) => item.inCart === false,
        );
        let selectedConfig: ConfigLoader | null = null;
        if (productConfigsNotInCart.length > 0) {
          selectedConfig =
            productConfigsNotInCart[productConfigsNotInCart.length - 1];
        } else if (projectData.productConfigs.length > 0) {
          selectedConfig = {
            ...cloneDeep(
              projectData.productConfigs[projectData.productConfigs.length - 1],
            ),
            id: "",
            inCart: false,
          };
        }
        if (selectedConfig) {
          dispatch(setConfigId(selectedConfig.id));
          dispatch(setConfigLoader(selectedConfig));
        }
      }
    }
  }, [projectData, config, dispatch, isSuccess, fetchProjectQuery, projectId]);

  //preload cart items
  useEffect(() => {
    if (productGroups && products && fabrics && productOptions && cartItems) {
      cartItems.forEach((productConfig: ConfigLoader) => {
        const cartItem = convertLoadedProductToCartItem(
          productConfig,
          products,
          productGroups,
          productOptions,
          fabrics,
        );
        if (cartItem) {
          dispatch(addToCart(cartItem));
        }
      });
      setCartInitComplete(true);
    }
  }, [dispatch, fabrics, cartItems, productGroups, productOptions, products]);

  // handle callback with auth code
  useEffect(() => {
    if (code) {
      dispatch(setLoading(true));
      userManager
        .signinRedirectCallback()
        .then((user) => {
          userManager.storeUser(user);
          dispatch(setToken(user.access_token));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.error("Login error", error);
        });
    }
    // check if user has signed in before and can auto login again
    if (!code && !isLoggedIn) {
      dispatch(setLoading(true));
      userManager.getUser().then((currentUser) => {
        if (currentUser) {
          dispatch(setToken(currentUser.access_token));
        }
        dispatch(setLoading(false));
      });
    }
  }, [code, dispatch, userManager, isLoggedIn]);

  useEffect(() => {
    if (isValidProjectIdFormat(projectId)) {
      return;
    }

    // clear config and project id
    dispatch(setProjectId(undefined));
    dispatch(setConfigId(undefined));
    navigate("/");
  }, [projectId, dispatch, navigate]);

  useEffect(() => {
    if (
      product &&
      isValidProjectIdFormat(projectId) &&
      isLoggedIn &&
      cartInitComplete
    ) {
      dispatch(setProjectSetupComplete(true));
    }
  }, [product, projectId, isLoggedIn, dispatch, cartInitComplete]);

  if (translationsLoading) {
    return (
      <Layout userManager={userManager} background>
        <div className="flex flex-1 items-center justify-center">
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  if (translationsError) {
    return (
      <Layout userManager={userManager} background>
        <div className="flex flex-1 items-center justify-center text-white text-sm">
          <div className="px-4 text-center">
            This service is currently unavailable. Please try again later.
            <br />
            We apologize for any inconvenience and appreciate your patience.
          </div>
        </div>
      </Layout>
    );
  }

  if (!isLoggedIn) {
    return (
      <Layout userManager={userManager} background>
        <Login userManager={userManager} />
      </Layout>
    );
  }

  if (product && !projectId && isLoggedIn) {
    return (
      <Layout userManager={userManager} background>
        <CreateProject />
      </Layout>
    );
  }

  return (
    <Layout userManager={userManager} background>
      <SplashScreen />
    </Layout>
  );
}

export default AppSetup;
