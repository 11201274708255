import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const BackIcon: React.FC<IconProps> = ({
  width = "8px",
  height = "8px",
  fill = "#000000",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill={fill}
    >
      <path d="M6.91699 2.58333H1.97533L3.48783 0.766664C3.55855 0.681574 3.59258 0.571874 3.58242 0.461696C3.57226 0.351519 3.51875 0.249889 3.43366 0.179165C3.34857 0.10844 3.23887 0.0744144 3.12869 0.0845726C3.01851 0.0947308 2.91688 0.148241 2.84616 0.233331L0.762826 2.73333C0.748809 2.75322 0.736275 2.77411 0.725325 2.79583C0.725325 2.81666 0.725326 2.82916 0.696159 2.85C0.677273 2.89777 0.667384 2.94863 0.666992 3C0.667384 3.05137 0.677273 3.10222 0.696159 3.15C0.696159 3.17083 0.696159 3.18333 0.725325 3.20416C0.736275 3.22589 0.748809 3.24678 0.762826 3.26666L2.84616 5.76666C2.88533 5.8137 2.93439 5.85152 2.98984 5.87745C3.0453 5.90337 3.10578 5.91676 3.16699 5.91666C3.26435 5.91685 3.3587 5.88295 3.43366 5.82083C3.47585 5.78585 3.51073 5.74289 3.53629 5.69442C3.56185 5.64594 3.5776 5.59289 3.58263 5.53832C3.58766 5.48375 3.58188 5.42872 3.56561 5.37638C3.54934 5.32405 3.52291 5.27543 3.48783 5.23333L1.97533 3.41666H6.91699C7.0275 3.41666 7.13348 3.37277 7.21162 3.29463C7.28976 3.21649 7.33366 3.1105 7.33366 3C7.33366 2.88949 7.28976 2.78351 7.21162 2.70537C7.13348 2.62723 7.0275 2.58333 6.91699 2.58333Z" />
    </svg>
  );
};

export default BackIcon;
