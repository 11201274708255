import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const ChevronDownIcon: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  fill = "#000000",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      className={className}
    >
      <path
        id="Icon"
        d="M1.4303 0L0 1.52528L8 10L16 1.51671L14.5697 0L8 6.96658L1.4303 0Z"
      />
    </svg>
  );
};

export default ChevronDownIcon;
