import React, { useEffect, useMemo, useState } from "react";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import "@babylonjs/loaders/glTF";
import {
  AssetManagerContextProvider,
  Engine,
  ILoadedModel,
} from "react-babylonjs";
import { Node, Scene } from "@babylonjs/core";
import { Color3 } from "@babylonjs/core";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  selectFabric,
  selectProduct,
  selectProductOptions,
  setProductModelScreenshot,
  selectProjectLogos,
  selectProductVariant,
} from "../../store/reducers/configReducer";
import { ProductModel } from "./ProductModel";
import { Fabric, ProductVariant } from "../../types";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import {
  getAllDisplayableProductOptionMeshes,
  getAllProductOptionDisabledMeshes,
  focusOnModel,
  filterProductOptions,
  enableMeshesByName,
  enableMeshes,
} from "./utils";
import { SceneEnvironment } from "./SceneEnvironment";
import { ProductModelPicture } from "./ProductModelPicture";
import { ProductModelLogos } from "./ProductModelLogos";
import {
  selectProductLogoPanelOpen,
  setProductModelLoadingState,
} from "../../store/reducers/uiReducer";

export type ProductSceneProps = {};

const ProductScene: React.FC<ProductSceneProps> = (
  _props: ProductSceneProps,
) => {
  const dispatch = useAppDispatch();
  const rootUrl = "/models/gltf/";

  // Scene
  // True if the product model is loaded and added to the scene.
  const [productModelIsReady, setProductModelIsReady] =
    useState<boolean>(false);
  const [cameraTarget, setCameraTarget] = useState<Vector3>(Vector3.Zero());
  const [cameraRadius, setCameraRadius] = useState<number>(1);
  const [sceneFilename, setSceneFilename] = useState<string>();
  const productVariant = useAppSelector<ProductVariant>(selectProductVariant);
  const projectLogos = useAppSelector(selectProjectLogos);

  // Product Options
  const product = useAppSelector((state) => selectProduct(state));
  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const filteredProductOptions = useMemo(() => {
    return filterProductOptions(productOptions, product);
  }, [product, productOptions]);

  const selectedProductOptions = useAppSelector(selectProductOptions);

  // All named meshes for all product options
  const [allProductOptionsMeshes, setAllProductOptionsMeshes] =
    useState<Array<string> | null>(null);
  // All named meshes that can be disabled for all product options.
  // Diffed against disabled meshes of the selected product options so they can be enabled.
  const [allProductOptionsDisabledMeshes, setAllProductOptionsDisabledMeshes] =
    useState<Array<string>>([]);

  // Fabric Colors
  const fabric = useAppSelector((state) => selectFabric(state));
  // Product Meshes
  const [mainColorMeshNames, setMainColorMeshNames] = useState<Array<string>>(
    [],
  );
  const [contrastColorMeshNames, setContrastColorMeshNames] = useState<
    Array<string>
  >([]);

  // UI
  const productLogoPanelOpen = useAppSelector(selectProductLogoPanelOpen);

  // Fabric Map with Fabric id and Color3 from Fabric web_format
  // also includes "main" and "contrast" color.
  const [fabricColorMap, setFabricColorMap] = useState<Map<string, Color3>>(
    new Map(),
  );

  const fabricsData = useGetDocumentByTypeQuery({
    type: "fabric",
    lang: "en-us",
  });

  useEffect(() => {
    // Create array with key, value pairs to create new Map
    // Fabric Map with Fabric id and Color3 from Fabric web_format
    const result = fabricsData.isSuccess
      ? fabricsData.data.map((curr: Fabric) => [
          `fabric_id:${curr.id}`,
          Color3.FromHexString(curr.data.web_format),
        ])
      : [];
    if (fabric) {
      result.push([
        "main",
        Color3.FromHexString(fabric.main?.data?.web_format || "#888"),
      ]);
      result.push([
        "contrast",
        Color3.FromHexString(fabric.contrast?.data?.web_format || "#888"),
      ]);
    }
    // Create new FabricColorMap from array
    if (result.length > 0) {
      setFabricColorMap(new Map(result));
    }
  }, [fabricsData, fabric]);

  useEffect(() => {
    if (filteredProductOptions) {
      setAllProductOptionsMeshes(
        getAllDisplayableProductOptionMeshes(filteredProductOptions),
      );
      setAllProductOptionsDisabledMeshes(
        getAllProductOptionDisabledMeshes(filteredProductOptions),
      );
    } else {
      setAllProductOptionsMeshes(null);
    }
  }, [filteredProductOptions]);

  // Update camera target to center loaded model
  const onModelLoaded = (model: ILoadedModel | null): void => {
    if (!model || !model.meshes) {
      console.error("Model.meshes not set");
      dispatch(setProductModelLoadingState("failed"));
      return;
    }

    const cameraConfig = focusOnModel(model);
    if (cameraConfig) {
      setCameraTarget(cameraConfig?.center || Vector3.Zero());
      setCameraRadius(cameraConfig.radius);
    }

    //
    if (product) {
      setMainColorMeshNames(
        product.data.meshes
          .filter((curr) => curr.default_fabric === "main_fabric")
          .map((curr) => curr.mesh),
      );
      setContrastColorMeshNames(
        product.data.meshes
          .filter((curr) => curr.default_fabric === "contrast_fabric")
          .map((curr) => curr.mesh),
      );
    }
    setProductModelIsReady(true);
    dispatch(setProductModelLoadingState("succeeded"));
  };

  useEffect(() => {
    if (!product) {
      // Setting sceneFilename to undefined will clear any loaded mesh model
      if (sceneFilename !== undefined) {
        setSceneFilename(undefined);
        setProductModelIsReady(false);
        setMainColorMeshNames([]);
        setContrastColorMeshNames([]);
        dispatch(setProductModelLoadingState("pending"));
      }
      return;
    }

    // Assuming the need to reset and reload every time product changes.
    setSceneFilename(undefined); // Reset to trigger unloading
    setProductModelIsReady(false);
    setMainColorMeshNames([]);
    setContrastColorMeshNames([]);
    window.setTimeout(() => {
      // Delay setting the filename to ensure components react to `undefined` state
      setSceneFilename(product.data.model_filename);
      dispatch(setProductModelLoadingState("pending"));
    }, 0);
  }, [product, productVariant]); // Remove `sceneFilename` and `dispatch` from dependencies to avoid unnecessary triggers

  // Set initial ProductModelLoadingState and cleanup
  useEffect(() => {
    dispatch(setProductModelLoadingState("pending"));
    return function cleanup() {
      dispatch(setProductModelLoadingState("idle"));
    };
  }, []);

  const onModelError = (): void => {
    dispatch(setProductModelLoadingState("failed"));
  };

  const onScreenshot = React.useCallback(
    (base64: string) => {
      dispatch(setProductModelScreenshot(base64));
    },
    [dispatch],
  );

  const onEnableMeshesByName = React.useCallback(
    (
      scene: Scene | null,
      meshNames: string[],
      value: boolean,
      force: boolean = false,
    ) => {
      if (!scene) {
        return;
      }
      if (force) {
        enableMeshesByName(scene, meshNames, value);
      } else if (!productLogoPanelOpen) {
        enableMeshesByName(scene, meshNames, value);
      }
    },
    [productLogoPanelOpen],
  );

  const onEnableMeshes = React.useCallback(
    (meshes: Node[], value: boolean, force: boolean = false) => {
      if (force) {
        enableMeshes(meshes, value);
      } else if (!productLogoPanelOpen) {
        enableMeshes(meshes, value);
      }
    },
    [productLogoPanelOpen],
  );

  return (
    <Engine antialias adaptToDeviceRatio canvasId="babylon-js">
      <SceneEnvironment
        cameraTarget={cameraTarget}
        cameraRadius={cameraRadius}
        sceneFilename={sceneFilename}
      >
        <AssetManagerContextProvider>
          <ProductModel
            rootUrl={`${rootUrl}${productVariant}/`}
            sceneFilename={sceneFilename}
            center={new Vector3(0, 0, 0)}
            onModelLoaded={onModelLoaded}
            onModelError={onModelError}
            selectedProductOptions={selectedProductOptions}
            allProductOptionMeshes={allProductOptionsMeshes}
            allProductOptionsDisabledMeshes={allProductOptionsDisabledMeshes}
            mainColorMeshNames={mainColorMeshNames}
            contrastColorMeshNames={contrastColorMeshNames}
            fabricColorMap={fabricColorMap}
            cameraRadius={cameraRadius}
            onEnableMeshes={onEnableMeshesByName}
          />
          <ProductModelPicture
            cameraTarget={cameraTarget}
            cameraRadius={cameraRadius}
            sceneFilename={sceneFilename}
            onScreenshot={onScreenshot}
            selectedProductOptions={selectedProductOptions}
            fabricColorMap={fabricColorMap}
          />
          <ProductModelLogos
            product={product}
            productModelIsReady={productModelIsReady}
            productOptions={filteredProductOptions}
            projectLogos={projectLogos}
            showHighlight={productLogoPanelOpen}
            onEnableMeshes={onEnableMeshes}
            selectedProductOptions={selectedProductOptions}
          />
        </AssetManagerContextProvider>
      </SceneEnvironment>
    </Engine>
  );
};

export { ProductScene };
