import { ReactNode } from "react";
import { useGetDocumentByTypeQuery } from "./api/prismic";
import { useFetchProductGroupsQuery, useFetchProductsQuery } from "./api/nest";

export function PrismicStaticDataManager({
  children,
}: {
  children: ReactNode;
}) {
  useFetchProductsQuery({});
  useFetchProductGroupsQuery({});
  useGetDocumentByTypeQuery({ type: "fabric", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "product_option", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "settings", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "supplier", lang: "en-us" });

  return <>{children}</>;
}
