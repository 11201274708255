import { useEffect, useState } from "react";
import { UserManager } from "oidc-client";
import Layout from "./Layout";
import { selectUser } from "../store/reducers/userReducer";
import Title from "./ui/Title";
import Login from "./Login";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectProjectId } from "../store/reducers/configReducer";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/Button";
import { toast } from "react-toastify";
import {
  useDuplicateProjectMutation,
  useFetchUserOrdersQuery,
} from "../api/nest";
import { Order } from "../types";
import PrismicTranslation from "./PrismicTranslation";
import BackIcon from "./ui/Icons/BackIcon";
import { resetCart, selectCart } from "../store/reducers/cartReducer";
import Modal from "./ui/Modal";

const reloadOnProjectChange = true;
const Orders = ({ userManager }: { userManager: UserManager }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const projectId = useAppSelector(selectProjectId);
  const cart = useAppSelector(selectCart);
  const isLoggedIn = !!user;
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pendingReorderId, setPendingReorderId] = useState<string | null>(null);
  const { data: orders } = useFetchUserOrdersQuery(user?.id);
  const [
    duplicateProject,
    { data: duplicateProjectResult, isError, isSuccess },
  ] = useDuplicateProjectMutation();

  const confirmReorder = async () => {
    if (!pendingReorderId) return;
    setIsModalOpen(false);
    dispatch(resetCart());
    await handleReOrder(pendingReorderId);
  };

  const handleReOrder = async (orderId: string) => {
    setLoading(true);
    const projectId = orders.find((order: Order) => order.id === orderId)
      ?.project?.id;

    if (!projectId) {
      setLoading(false);
      toast.error(<PrismicTranslation field="project_not_found" simple />);
      return;
    }
    await duplicateProject(projectId);
  };

  const reOrder = (orderId: string) => {
    if (cart.products.length > 0) {
      setPendingReorderId(orderId);
      setIsModalOpen(true);
    } else {
      handleReOrder(orderId);
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        <PrismicTranslation field="error_duplicate_project" simple />,
      );
    }
    if (isSuccess) {
      if (reloadOnProjectChange) {
        window.location.replace(`/${duplicateProjectResult.id}`);
      } else {
        navigate(`/${duplicateProjectResult.id}`);
      }
    }
    setLoading(false);
  }, [isSuccess, isError, duplicateProjectResult, navigate]);

  const createNewProject = () => {
    dispatch(resetCart());
    navigate("/");
  };

  return (
    <Layout userManager={userManager} background>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full pt-4 lg:px-10">
            {projectId && (
              <div
                onClick={() => navigate(`/${projectId || ""}`)}
                className="cursor-pointer w-full flex items-center gap-2 ml-2 underline text-text text-sm"
              >
                <BackIcon />
                <span>
                  <PrismicTranslation field="continue_shopping" simple />
                </span>
              </div>
            )}
            {!projectId && (
              <div className="flex gap-4 py-4 align-middle">
                <Button onClick={createNewProject} label="new_project" />
              </div>
            )}
            <Title size={1} label="my_orders" />
            {orders?.map((order: Order) => (
              <div
                className="w-full bg-white p-4 border border-gray"
                key={order.id}
              >
                <Title
                  label={`${order.project?.name} | ${order.id}`}
                  className="text-text font-bold text-lg"
                />
                <span className="font-bold text-md">
                  {order.totalPrice?.toFixed(2)}€
                </span>
                <div className="flex gap-4">
                  {order.cart.map((cartItem) => (
                    <img
                      className="w-[56px] h-[56px] object-contain"
                      src={cartItem.productModelPicture || "/placeholder.png"}
                      alt={cartItem.product?.data?.name || "image"}
                      key={cartItem.id}
                    />
                  ))}
                </div>
                <div className="text-text mt-1 flex gap-4">
                  <Button
                    disabled={loading}
                    onClick={() => reOrder(order.id)}
                    label="reorder"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Title label="reorder_warning_title" />
        <PrismicTranslation field={"reorder_warning_content"} simple />

        <div className="flex gap-4 mt-4">
          <Button label="cancel" onClick={() => setIsModalOpen(false)} />
          <Button label="continue" onClick={confirmReorder} />
        </div>
      </Modal>
    </Layout>
  );
};

export default Orders;
