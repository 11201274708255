import { ConfiguratorSelector } from "../ConfiguratorSelector";
import {
  resetCompleteYourLookFabrics,
  selectCompletYourLookFabric,
  selectConfigId,
  selectConfigLoader,
  selectFabric,
  selectFabricSupplier,
  selectProduct,
  setSelectedFabric,
} from "../../../store/reducers/configReducer";
import { Fabric, Product, ProductFabricData, Supplier } from "../../../types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import CircularProgress from "../../ui/CircularProgress";
import Title from "../../ui/Title";
import SupplierFabricColors from "./SupplierFabricColors";
import { sortBy } from "lodash";
import {
  selectLanguage,
  selectTranslations,
} from "../../../store/reducers/uiReducer";
import { getPrismicTranslation } from "../../../utils";

const FabricSelector = () => {
  const dispatch = useAppDispatch();
  const configId = useAppSelector(selectConfigId);
  const language = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const configLoader = useAppSelector(selectConfigLoader);
  const product = useAppSelector<Product | undefined>(selectProduct);
  const fabric = useAppSelector(selectFabric);
  const fabricSupplier = useAppSelector(selectFabricSupplier);
  const completeYourLookFabrics = useAppSelector(selectCompletYourLookFabric);
  const useYourLookFabricsRef = useRef<boolean>(false); // Should be set to true after YourLook Fabrics are set for the Product

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productMainFabrics, setProductMainFabrics] =
    useState<ProductFabricData[]>();
  const [productContrastFabrics, setProductContrastFabrics] =
    useState<ProductFabricData[]>();
  const [productFabricSuppliers, setProductFabricSuppliers] =
    useState<Supplier[]>();

  // Get a list of all fabrics
  const { data: allFabrics } = useGetDocumentByTypeQuery({
    type: "fabric",
    lang: "en-us",
  });
  // Get a list of all suppliers
  const { data: suppliers } = useGetDocumentByTypeQuery({
    type: "supplier",
    lang: "en-us",
  });

  useEffect(() => {
    setIsLoading(!(Array.isArray(allFabrics) && Array.isArray(suppliers)));
  }, [allFabrics, suppliers]);

  const fabricMainName = useMemo(() => {
    return getPrismicTranslation(language, "name", fabric?.main?.data);
  }, [language, fabric]);

  const fabricContrastName = useMemo(() => {
    return getPrismicTranslation(language, "name", fabric?.contrast?.data);
  }, [language, fabric]);

  const selectedMainTitle = fabric?.main?.data?.name
    ? `${getPrismicTranslation(
        language,
        "config_option_title_main_fabrics",
        translations,
      )}: <span class="font-bold">${fabricMainName}</span><br />`
    : "";
  const selectedContrastTitle = fabric?.contrast?.data?.name
    ? `${getPrismicTranslation(
        language,
        "config_option_title_contrast_fabrics",
        translations,
      )} : <span class="font-bold">${fabricContrastName}</span>`
    : "";

  // Build list of fabrics for this product
  useEffect(() => {
    if (product && Array.isArray(allFabrics) && Array.isArray(suppliers)) {
      // Main fabrics
      const productMainFabricsMap = new Map<string, ProductFabricData>();
      const allFabricMap = new Map<string, Fabric>(
        allFabrics.map((item) => [item.id, item]),
      );
      const supplierMap = new Map<string, Supplier>(
        suppliers.map((item) => [item.id, item]),
      );
      product.data.main_fabrics.forEach((item) => {
        const fabric = allFabricMap.get(item.fabric.id);
        if (fabric?.data?.supplier?.id) {
          const supplier = supplierMap.get(fabric.data.supplier.id);
          if (supplier) {
            productMainFabricsMap.set(item.fabric.id, {
              colorOption: "main",
              supplier,
              fabric,
              defaultSelected: item.selected,
            });
          }
        }
      });
      const productMainFabricsValues = sortBy(
        Array.from(productMainFabricsMap.values()),
        "fabric.data.name",
      );
      setProductMainFabrics(productMainFabricsValues);

      // Contrast fabrics
      const productContractFabricsMap = new Map<string, ProductFabricData>();
      product.data.contrast_fabrics.forEach((item) => {
        const fabric = allFabricMap.get(item.fabric.id);
        if (fabric?.data?.supplier?.id) {
          const supplier = supplierMap.get(fabric.data.supplier.id);
          if (supplier) {
            productContractFabricsMap.set(item.fabric.id, {
              colorOption: "contrast",
              supplier,
              fabric,
              defaultSelected: item.selected,
            });
          }
        }
      });

      const productContrastFabricsValues = sortBy(
        Array.from(productContractFabricsMap.values()),
        "fabric.data.name",
      );
      setProductContrastFabrics(productContrastFabricsValues);

      // List of fabric suppliers used by the product
      const usedFabricSuppliers = new Map<string, Supplier>();
      [
        ...Array.from(productMainFabricsMap.values()),
        ...Array.from(productContractFabricsMap.values()),
      ].forEach((item) => {
        // Supplier in SupplierMap has full details of the supplier. Supplier in fabric.data is limited.
        const supplier = supplierMap.get(item.supplier.id);
        if (supplier) {
          usedFabricSuppliers.set(supplier.id, supplier);
        }
      });
      setProductFabricSuppliers(Array.from(usedFabricSuppliers.values()));

      if (!configId) {
        // Get default main fabric or first main fabric
        const mainFabricDefault =
          productMainFabricsValues.find((item) => item.defaultSelected) ||
          productMainFabricsValues[0];
        if (mainFabricDefault) {
          dispatch(
            setSelectedFabric({
              fabric: mainFabricDefault.fabric,
              colorOption: "main",
            }),
          );
        }

        // Get default contract fabric or first contrast fabric matching the supplier of the main fabric
        const contrastFabricDefault =
          productContrastFabricsValues.find((item) => item.defaultSelected) ||
          productContrastFabricsValues.find(
            (item) => item.supplier.id === mainFabricDefault.supplier.id,
          );
        if (contrastFabricDefault) {
          dispatch(
            setSelectedFabric({
              fabric: contrastFabricDefault.fabric,
              colorOption: "contrast",
            }),
          );
        }
      }
    }
  }, [product, allFabrics, suppliers, dispatch, configId]);

  // Update the main or contrast fabrics if the suppliers do not match.
  // The ConfigReducer will set fabricSupplier when a fabric is set.
  useEffect(() => {
    if (
      !fabric ||
      !fabricSupplier ||
      !productMainFabrics ||
      !productContrastFabrics
    ) {
      return;
    }
    // Main fabric should match supplier of selected fabric
    if (fabric.main?.data.supplier?.id !== fabricSupplier.id) {
      const match = productMainFabrics.find(
        (item) => item.supplier.id === fabricSupplier.id && item.fabric.id,
      );
      if (match && match.fabric) {
        dispatch(
          setSelectedFabric({
            fabric: match.fabric,
            colorOption: "main",
          }),
        );
      }
    }

    // Contrast fabric should match supplier of selected fabric
    if (fabric.contrast?.data.supplier?.id !== fabricSupplier.id) {
      const match = productContrastFabrics.find(
        (item) => item.supplier.id === fabricSupplier.id && item.fabric.id,
      );
      if (match && match.fabric) {
        dispatch(
          setSelectedFabric({
            fabric: match.fabric,
            colorOption: "contrast",
          }),
        );
      }
    }
  }, [
    fabric,
    fabricSupplier,
    dispatch,
    productMainFabrics,
    productContrastFabrics,
  ]);

  // If there is a config loaded for this product update the main and contrast fabrics if needed
  useEffect(() => {
    if (!configId || useYourLookFabricsRef.current === true) {
      return;
    }

    if (
      configId &&
      configLoader &&
      configLoader.id === configId &&
      product &&
      configLoader?.product === product.id &&
      productMainFabrics &&
      productContrastFabrics
    ) {
      if (configLoader.mainFabric && productMainFabrics) {
        const fabricData = productMainFabrics.find(
          (item) => item.fabric.id === configLoader.mainFabric,
        );
        if (fabricData) {
          dispatch(
            setSelectedFabric({
              fabric: fabricData.fabric,
              colorOption: "main",
            }),
          );
        }
      }

      if (configLoader.contrastFabric && productContrastFabrics) {
        const fabricData = productContrastFabrics.find(
          (item) => item.fabric.id === configLoader.contrastFabric,
        );
        if (fabricData) {
          dispatch(
            setSelectedFabric({
              fabric: fabricData.fabric,
              colorOption: "contrast",
            }),
          );
        }
      }
    }
  }, [
    configId,
    configLoader,
    product,
    productMainFabrics,
    productContrastFabrics,
    dispatch,
  ]);

  useEffect(() => {
    if (product && completeYourLookFabrics) {
      // Set ref to prevent Fabrics in configLoader to be applied again after resetCompleteYourLookFabrics
      useYourLookFabricsRef.current = true;
      if (completeYourLookFabrics.main) {
        dispatch(
          setSelectedFabric({
            fabric: completeYourLookFabrics.main,
            colorOption: "main",
          }),
        );
      }
      if (completeYourLookFabrics.contrast) {
        dispatch(
          setSelectedFabric({
            fabric: completeYourLookFabrics.contrast,
            colorOption: "contrast",
          }),
        );
      }
      dispatch(resetCompleteYourLookFabrics());
    }
  }, [product, completeYourLookFabrics, dispatch]);

  return (
    <ConfiguratorSelector
      tabKey={"fabric"}
      hasOptions={!isLoading}
      title="config_option_title_3"
      selected={selectedMainTitle + selectedContrastTitle}
    >
      <>
        {/* LOADING */}
        {isLoading && <CircularProgress />}
        {/* MAIN COLORS */}
        <Title size={3} label="config_option_title_main_fabrics" />
        {productFabricSuppliers &&
          productFabricSuppliers.map((supplier: Supplier) => (
            <SupplierFabricColors
              key={supplier.id}
              supplier={supplier}
              colorOption="main"
              defaultOption={fabric}
              disabled={fabricSupplier?.id !== supplier.id}
              fabrics={productMainFabrics
                ?.filter((item) => item.supplier.id === supplier.id)
                .map((item) => item.fabric)}
            />
          ))}
        {/* CONTRAST COLORS */}
        <Title size={3} label="config_option_title_contrast_fabrics" />
        {productFabricSuppliers &&
          productFabricSuppliers.map((supplier: Supplier) => (
            <SupplierFabricColors
              key={supplier.id}
              supplier={supplier}
              colorOption="contrast"
              defaultOption={fabric}
              disabled={fabricSupplier?.id !== supplier.id}
              fabrics={productContrastFabrics
                ?.filter((item) => item.supplier.id === supplier.id)
                .map((item) => item.fabric)}
            />
          ))}
      </>
    </ConfiguratorSelector>
  );
};
export default FabricSelector;
