import { FC, useState } from "react";
import PrismicTranslation from "../PrismicTranslation";
import Title from "../ui/Title";
import { CartProduct, Size, SizingObject } from "../../types";
import { getMininumOrderQuantity } from "../../utils";
import { Input } from "../ui/Input";
import Modal from "../ui/Modal";
import SizeIcon from "../ui/Icons/SizeIcon";

export const OrderQuantity: FC<{
  cartItem: CartProduct;
  handleCartAmount: Function;
  orderQuantity: number;
  settings: any;
  sizeChart?: string;
}> = ({ cartItem, handleCartAmount, orderQuantity, settings, sizeChart }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  // Define constants for the minimum order quantities
  const MINIMUM_TOTAL_QUANTITY = getMininumOrderQuantity(settings); // Overall minimum
  const MINIMUM_SIZINGOBJECT_QUANTITY = 25; // Per-sizingObject minimum

  return (
    <div className="col-span-6 md:col-span-4">
      <div className="flex gap-2 ">
        <Title label="choose_size" />
        {sizeChart && (
          <button
            onClick={() => setModalOpen(true)}
            className="flex items-center gap-2 text-sm border border-neutrals-300 rounded-full  bg-white p-2 pr-3.5  hover:bg-primary-200 hover:border-primary-300"
          >
            <SizeIcon width={16} height={16} />
            <PrismicTranslation field="view_size_chart" simple />
          </button>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        size="large"
      >
        <img src={sizeChart} alt="Size Chart" className="w-full h-auto" />
      </Modal>

      <div className="flex flex-wrap gap-4">
        {/* Warning for overall minimum order quantity */}
        {orderQuantity > 0 && orderQuantity < MINIMUM_TOTAL_QUANTITY && (
          <span className="text-red font-bold border border-red p-4 bg-red/10 rounded">
            <PrismicTranslation
              field="min_order_quantity"
              options={{ min_order_quantity: MINIMUM_TOTAL_QUANTITY }}
              simple
            />
          </span>
        )}

        {cartItem.amount?.map((sizingObject: SizingObject) => {
          // Calculate the total quantity for the current sizingObject
          const sizingObjectTotal = sizingObject.items.reduce(
            (total, { amount }) => total + (amount ?? 0), // Handle possible undefined values
            0,
          );

          return (
            <div className="w-full" key={sizingObject.id}>
              <div className="font-bold">
                <PrismicTranslation
                  field={sizingObject.primary?.translation_key}
                  simple
                />
              </div>

              {/* Warning for sizingObject-specific minimum quantity */}
              {sizingObjectTotal > 0 &&
                sizingObjectTotal < MINIMUM_SIZINGOBJECT_QUANTITY && (
                  <div className="text-red font-bold text-sm mb-2">
                    <PrismicTranslation
                      field="min_sizing_quantity"
                      options={{
                        sizing_object: sizingObject.primary?.translation_key,
                        remaining:
                          MINIMUM_SIZINGOBJECT_QUANTITY - sizingObjectTotal,
                      }}
                      simple
                    />
                  </div>
                )}
              <div className="flex flex-wrap gap-2">
                {sizingObject.items.map(({ size, amount }: Size) => (
                  <div key={size} className="flex w-[36px] flex-wrap">
                    <Input
                      label={size}
                      labelClassName="text-text"
                      alternateLabel
                      defaultValue={amount ?? ""}
                      placeholder="0"
                      type="number"
                      className="text-center !text-black !p-0.5"
                      onChange={(e) =>
                        handleCartAmount(
                          sizingObject.primary?.translation_key,
                          size,
                          e.currentTarget.valueAsNumber,
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
