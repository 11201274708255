import { ConfiguratorSelector } from "./ConfiguratorSelector";
import {
  selectConfigId,
  selectConfigLoader,
  selectProduct,
  selectProductGroup,
  setConfigId,
  setSelectedProduct,
} from "../../store/reducers/configReducer";
import { Product, ProductGroup } from "../../types";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getPrismicTranslation } from "../../utils";
import { selectLanguage } from "../../store/reducers/uiReducer";
import { useFetchProductsQuery } from "../../api/nest";

const ProductSelector = () => {
  const dispatch = useAppDispatch();
  const configId = useAppSelector(selectConfigId);
  const configLoader = useAppSelector(selectConfigLoader);
  const language = useAppSelector(selectLanguage);
  const productGroup = useAppSelector<ProductGroup | undefined>((state) =>
    selectProductGroup(state),
  );
  const product = useAppSelector<Product | undefined>(selectProduct);
  const { data: products, isSuccess } = useFetchProductsQuery({});

  const productGroupProducts: Product[] = products?.filter(
    (product: Product) => product.data.parent.id === productGroup?.id,
  );

  const isSelected = (option: Product) => {
    return product?.id === option.id;
  };

  const onSelectProduct = (option: Product) => {
    dispatch(setConfigId(undefined));
    dispatch(setSelectedProduct(option));
  };

  const setDefaultProduct = () => {
    if (configId && configLoader) {
      dispatch(
        setSelectedProduct(
          productGroupProducts.find(
            (product: Product) => product.id === configLoader.product,
          ),
        ),
      );
    } else {
      dispatch(setSelectedProduct(productGroupProducts[0]));
    }
  };

  useEffect(() => {
    if (!productGroupProducts?.length) return;

    if (!product) {
      setDefaultProduct();
    }
  }, [
    dispatch,
    isSuccess,
    product,
    productGroupProducts,
    configId,
    configLoader,
  ]);

  return (
    <ConfiguratorSelector
      tabKey={"product"}
      hasOptions={productGroupProducts?.length > 0}
      selected={getPrismicTranslation(language, "name", product?.data)}
      title="config_option_title_2"
    >
      <div className="flex flex-wrap items-center justify-center gap-2">
        {productGroupProducts?.map((option) => (
          <div
            key={option.id}
            onClick={() => onSelectProduct(option)}
            className={`cursor-pointer font-bold text-center p-3 rounded flex-1 border border-gray text-sm
              ${
                isSelected(option)
                  ? "bg-primary-200 border-primary-300"
                  : "hover:bg-neutrals-200 hover:border-neutrals-300"
              }`}
          >
            {getPrismicTranslation(language, "name", option?.data)}
          </div>
        ))}
      </div>
    </ConfiguratorSelector>
  );
};

export default ProductSelector;
