import { UserManager } from "oidc-client";
import Layout from "../Layout";
import { useAppSelector } from "../../hooks";
import {
  selectDiscount,
  selectOrderInformation,
  selectUser,
} from "../../store/reducers/userReducer";
import Title from "../ui/Title";
import Login from "../Login";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Summary from "./Summary";
import { Button } from "../ui/Button";
import { selectCartItems } from "../../store/reducers/cartReducer";
import { mapCartToOrder } from "../../utils";
import { selectFabricSupplier } from "../../store/reducers/configReducer";
import { toast } from "react-toastify";
import { useCreateOrderMutation } from "../../api/nest";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import BackIcon from "../ui/Icons/BackIcon";

const Delivery = ({ userManager }: { userManager: UserManager }) => {
  const user = useAppSelector(selectUser);
  const discount = useAppSelector(selectDiscount);
  const orderInformation = useAppSelector(selectOrderInformation);
  const cartItems = useAppSelector(selectCartItems);
  const fabricSupplier = useAppSelector(selectFabricSupplier);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const isLoggedIn = !!user;
  const isGuest = user?.scope === "guest";

  const [createOrder, { data: createdOrder, isSuccess, isError }] =
    useCreateOrderMutation();

  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  const placeOrder = async () => {
    const orderDto = mapCartToOrder(
      cartItems,
      fabricSupplier,
      projectId,
      orderInformation,
      user?.id,
      discount,
      settings,
    );
    console.log("[CHK]placeOrder", orderDto);
    orderDto.cart.forEach((item) =>
      console.log("[CHK]placeOrder item projectLogos", item.projectLogos),
    );
    await createOrder(orderDto);
  };

  useEffect(() => {
    if (isError) {
      toast.error(<PrismicTranslation field={"error_order"} simple />);
      return;
    }
    if (isSuccess) {
      navigate("/thanks", {
        state: { orderId: createdOrder.raw[0]?.id },
      });
    }
  }, [createOrder, isError, isSuccess, navigate]);

  // === DEV start, remove after test ==========================================
  useEffect(() => {
    console.log("[CHK]-cartItems", cartItems);
    const orderDto = mapCartToOrder(
      cartItems,
      fabricSupplier,
      projectId,
      orderInformation,
      user?.id,
      discount,
      settings,
    );
    console.log("[CHK]-cartItems-orderDto", orderDto);
    orderDto.cart.forEach((item) =>
      console.log("[CHK]-cartItems-orderDto-projectLogos", item.projectLogos),
    );
  }, [cartItems]);
  // === DEV end =============================================================

  return (
    <Layout userManager={userManager} background={!isLoggedIn}>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full lg:w-2/3 pt-4 lg:px-10">
            <div className="flex">
              <div
                onClick={() => navigate(`/cart/${projectId}`)}
                className="cursor-pointer flex items-center gap-2 ml-2 underline text-text text-sm"
              >
                <BackIcon />
                <span>
                  <PrismicTranslation field={"breadcrumb_cart"} simple />
                </span>
              </div>
              <div
                onClick={() => navigate(`/delivery/${projectId}`)}
                className="cursor-pointer flex items-center gap-2 ml-2 underline text-text text-sm"
              >
                <BackIcon />
                <span>
                  <PrismicTranslation field={"breadcrumb_delivery"} simple />
                </span>
              </div>
            </div>
            <div className="mb-8">
              <Title label={"review_order_title"} />
              {isGuest ? (
                <div className="bg-white p-4 h-full grid grid-cols-2 gap-4">
                  <div className="col-span-2 lg:col-span-1">
                    <Title tertiary label={"review_contact_details"} />
                    <div className="flex flex-wrap flex-col">
                      <span>{orderInformation?.email}</span>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <Title tertiary label={"review_distributor"} />
                    {orderInformation?.distributor?.name}
                  </div>
                </div>
              ) : (
                <div className="bg-white p-4 h-full grid grid-cols-2 gap-4">
                  <div className="col-span-2 lg:col-span-1">
                    <Title tertiary label={"review_company_details"} />
                    <div className="flex flex-wrap flex-col">
                      <span>{orderInformation?.companyName}</span>
                      <span>{orderInformation?.vat}</span>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <Title tertiary label={"review_billing_address"} />
                    <div className="flex flex-wrap flex-col">
                      <span>
                        {orderInformation?.billingAddress?.street}{" "}
                        {orderInformation?.billingAddress?.street2}{" "}
                      </span>
                      <span>
                        {orderInformation?.billingAddress?.zipCode}{" "}
                        {orderInformation?.billingAddress?.city}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <Title tertiary label={"review_contact_details"} />
                    <div className="flex flex-wrap flex-col">
                      <span>
                        {orderInformation?.firstName}{" "}
                        {orderInformation?.lastName}
                      </span>
                      <span>{orderInformation?.email}</span>
                      <span>{orderInformation?.phone}</span>
                    </div>
                  </div>
                  {orderInformation?.shippingAddress && (
                    <div className="col-span-2 lg:col-span-1">
                      <Title tertiary label={"review_shipping_address"} />
                      <div className="flex flex-wrap flex-col">
                        <span>
                          {orderInformation?.shippingAddress?.street}{" "}
                          {orderInformation?.shippingAddress?.street2}{" "}
                        </span>
                        <span>
                          {orderInformation?.shippingAddress?.zipCode}{" "}
                          {orderInformation?.shippingAddress?.city}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col p-8 justify-between w-full md:w-1/3 bg-white max-h-[calc(100vh-88px)]">
            <Summary />
            <div className="pr-4 pt-4 bg-white">
              <Button
                full
                disabled={!termsAccepted}
                onClick={placeOrder}
                label={"button_place_order"}
              />
              <div
                className="flex gap-2 mt-2 cursor-pointer"
                onClick={() => setTermsAccepted(!termsAccepted)}
              >
                <input
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  type="checkbox"
                />
                <span>
                  <PrismicTranslation field={"terms"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Delivery;
