import { useCallback, useState } from "react";
import { useDownloadProductFicheMutation } from "../../../api/nest";
import { Product } from "../../../types";
import { useAppSelector } from "../../../hooks";
import { selectProductModelPicture } from "../../../store/reducers/configReducer";
import { selectCountryLanguage } from "../../../store/reducers/uiReducer";
import DownloadIcon from "../../ui/Icons/DownloadIcon";
import CircularProgress from "../../ui/CircularProgress";

const openDownload = true;

export const DownloadProductFiche = ({
  productConfig,
}: {
  productConfig: { id?: string; product?: Product };
}) => {
  const [downloadProductFiche] = useDownloadProductFicheMutation();
  const productImages = useAppSelector(selectProductModelPicture);
  const userLanguage = useAppSelector(selectCountryLanguage);
  const [isLoading, setIsLoading] = useState(false);

  const download = useCallback(() => {
    if (productConfig.id && productConfig.product) {
      setIsLoading(true);
      downloadProductFiche({
        productFicheData: {
          productConfig: { id: productConfig.id },
          lang: userLanguage,
        },
        cb: async (response: any) => {
          try {
            if (!openDownload) {
              setIsLoading(false);
              return;
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "ProductFiche.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Failed to download product fiche:", error);
          } finally {
            setIsLoading(false);
          }
        },
      });
    }
  }, [downloadProductFiche, productImages, userLanguage, productConfig]);

  return (
    <div
      onClick={download}
      className="cursor-pointer group border border-neutrals-300 rounded-full flex items-center bg-white p-2 align-middle absolute top-16 left-4 hover:bg-primary-200 hover:border-primary-300"
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DownloadIcon className="fill-neutrals-400 group-hover:fill-[#708EC8] pointer-events-none" />
      )}
    </div>
  );
};

export default DownloadProductFiche;
