import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const UploadIcon: React.FC<IconProps> = ({
  width = "16px",
  height = "16px",
  fill = "#000000",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      className={className}
    >
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20375 0.155883C9.26499 0.194821 9.32228 0.240605 9.3744 0.292737L13.2633 4.18259C13.6538 4.57316 13.6537 5.20633 13.2631 5.5968C12.8725 5.98728 12.2394 5.9872 11.8489 5.59663L9.66697 3.41416V10.3334C9.66697 10.8857 9.21925 11.3334 8.66697 11.3334C8.11468 11.3334 7.66697 10.8857 7.66697 10.3334V3.41462L5.4855 5.59663C5.09503 5.9872 4.46186 5.98728 4.07129 5.5968C3.68072 5.20633 3.68064 4.57316 4.07112 4.18259L7.94704 0.30571C8.12892 0.117085 8.38424 -0.000244141 8.66697 -0.000244141C8.66701 -0.000244141 8.66705 -0.000244134 8.66709 -0.000244134C8.66712 -0.000244134 8.66716 -0.000244141 8.6672 -0.000244141C8.67537 -0.000244141 8.68353 -0.000144152 8.69168 5.51486e-05C8.87455 0.00452812 9.05141 0.0590248 9.20375 0.155883ZM8.69168 5.51486e-05C8.87986 0.00461899 9.05515 0.0611649 9.20375 0.155883L8.69168 5.51486e-05ZM1.66699 9.33236C2.21928 9.33236 2.66699 9.78007 2.66699 10.3324V13.4442C2.66699 13.5918 2.72558 13.7332 2.8298 13.8374C2.934 13.9417 3.07529 14.0002 3.22255 14.0002H14.1114C14.2587 14.0002 14.4 13.9417 14.5042 13.8374C14.6084 13.7332 14.667 13.5918 14.667 13.4442V10.3324C14.667 9.78007 15.1147 9.33236 15.667 9.33236C16.2193 9.33236 16.667 9.78007 16.667 10.3324V13.4442C16.667 14.122 16.3978 14.7721 15.9186 15.2515C15.4393 15.7308 14.7893 16.0002 14.1114 16.0002H3.22255C2.54469 16.0002 1.89465 15.7308 1.41541 15.2515C0.936177 14.7721 0.666992 14.122 0.666992 13.4442V10.3324C0.666992 9.78007 1.11471 9.33236 1.66699 9.33236Z"
      />
    </svg>
  );
};

export default UploadIcon;
