import React from "react";

interface IconProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
}

const DuplicateIcon: React.FC<IconProps> = ({
  width = "13px",
  height = "12px",
  fill = "currentColor",
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill={fill}
      className={className}
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85825 8.99955H5.14362C3.96005 8.99955 3.00045 8.03995 3.00045 6.85638V2.14318C3.00045 0.959607 3.95988 0 5.14362 0H9.85843C11.0422 0 12.0016 0.959607 12.0016 2.14318V6.8562C12.0016 8.03995 11.042 8.99955 9.85825 8.99955ZM10.7212 2.14871C10.7212 1.67525 10.3374 1.29144 9.86397 1.29144H5.14737C4.67391 1.29144 4.2901 1.67525 4.2901 2.14871V6.86406C4.2901 7.33752 4.67391 7.72133 5.14737 7.72133H9.86397C10.3374 7.72133 10.7212 7.33752 10.7212 6.86406V2.14871ZM9.43176 5.15148H8.13764V6.43614C8.13764 6.67046 7.94761 6.86066 7.71311 6.86066H7.28448C7.04998 6.86066 6.85995 6.67064 6.85995 6.43614V5.15148H5.56583C5.33133 5.15148 5.14148 4.96145 5.14148 4.72695V4.28867C5.14148 4.05417 5.33151 3.86415 5.56583 3.86415H6.85995V2.57003C6.85995 2.33553 7.04998 2.1455 7.28448 2.1455H7.71311C7.94761 2.1455 8.13764 2.33553 8.13764 2.57003V3.86415H9.43176C9.66626 3.86415 9.85629 4.05417 9.85629 4.28867V4.72695C9.85629 4.96145 9.66626 5.15148 9.43176 5.15148ZM2.14693 10.7218H5.99679C6.46543 10.7218 6.84495 10.3453 6.85263 9.87844H8.14157C8.1296 11.0518 7.17678 12 6.00071 12H2.14318C0.959607 12 0 11.0404 0 9.85682V6.00107C0 4.81786 0.958893 3.85879 2.14193 3.85807V5.15952C1.67096 5.16237 1.28983 5.54457 1.28983 6.01625V9.86468C1.28966 10.338 1.67346 10.7218 2.14693 10.7218Z"
      />
    </svg>
  );
};

export default DuplicateIcon;
